module.exports = [{
      plugin: require('/codebuild/output/src624843067/src/lenny-s.com/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/codebuild/output/src624843067/src/lenny-s.com/src/components/Layout.js"},
    },{
      plugin: require('/codebuild/output/src624843067/src/lenny-s.com/node_modules/gatsby-mdx/gatsby-browser'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/codebuild/output/src624843067/src/lenny-s.com/src/components/DefaultTemplate.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":630,"withWebp":true,"quality":80,"backgroundColor":null}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"}]},
    },{
      plugin: require('/codebuild/output/src624843067/src/lenny-s.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-23057335-1"},
    },{
      plugin: require('/codebuild/output/src624843067/src/lenny-s.com/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src624843067/src/lenny-s.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
